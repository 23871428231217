function install(Vue) {
    Vue.createProps = function createProps(name, props) {
      if(typeof global.vueParams === 'undefined'){
        global.vueParams = {};
      }
      let length = global.vueParams['length'] || Object.keys(global.vueParams).length;
      let key = `${name}_${length}`;
      global.vueParams[key] = Vue.observable({props: props});
      global.vueParams['length'] = length + 1;
      return key;
    };

    Vue.getProps = function getProps(key) {
      if(typeof global.vueParams === 'undefined'){
        global.vueParams = {};
      }
      if(!global.vueParams.hasOwnProperty(key)){
        return null;
      }
      return global.vueParams[key].props ?? null;
    };

    Vue.updateProps = function updateProps(key, props) {
      Vue.set(global.vueParams[key],'props', props);
    };

    Vue.deleteProps = function deleteProps(key) {
      delete global.vueParams[key];
    };
}

export default install;

if (typeof global !== 'undefined' && global.Vue) {
  global.Vue.use(install);
  if (install.installed) {
    install.installed = false;
  }
}