(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vuex"), require("vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["Vuex", , "axios"], factory);
	else if(typeof exports === 'object')
		exports["prz-componentloader"] = factory(require("Vuex"), require("vue"), require("axios"));
	else
		root["prz-componentloader"] = factory(root["Vuex"], root["Vue"], root["axios"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 